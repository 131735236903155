/* blockquote */.big-quote {
    p {
        font-family: $font2;
        font-size: 2rem;
        line-height: 1.125;
        font-weight: 300;

        &:first-child::before {
            content: "“";
        }

        &:last-child::after {
            content: "”";
        }
	}

	&--no-really {
		margin: 0 auto !important;
		max-width: 60rem;

		p {

			@include mq( 600px ) {
				font-size: 3rem;
			}
		}
	}
}