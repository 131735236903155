.post-extract {

    @include mq(post-extract) {
        display: flex;
        align-items: flex-start;
    }

    &__image {
		display: block;
        margin-right: 1.5rem;
        margin-bottom: 1rem;

        @include mq(post-extract) {
			flex: 1 1 auto;
            margin-bottom: 0;
            width: 50%;
		}

		div {
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			padding-bottom: 62%;
		}
    }

    a.post-extract__image {
        &:focus,
        &:hover {
            opacity: 0.8;
        }
    }

    &__content {

        @include mq(post-extract) {
            flex: 1 1 auto;
            width: 50%;
        }
	}

	&__category {
		display: block;
		margin-bottom: 1rem;
	}

    &__heading {
		font-size: 1.5rem;
		margin-bottom: 1rem;
    }

    &__body {
        font-size: 0.875rem;
    }

    &__link {
        margin-top: auto;
        margin-bottom: 2px;
	}
}



/**
Mini version. Stacked, no body or link.
*/
.post-extract--mini {

	.post-extract__image {

		div {
			padding-bottom: 56.25%;
		}
	}
	.post-extract__category {
		margin-bottom: 1.5rem;
	}
	.post-extract__heading {
		font-size: 1.5rem;
	}
}


.post-extract--square,
.post-extract--short,
.post-extract--tall,
.post-extract--video {
	flex-direction: column;
	text-align: center;

    .post-extract__image {
		width: 100%;
	}

	.post-extract__content {
		width: 100%;
	}

	@include mq(post-extract) {

		.post-extract__image {
			flex: 0 0 auto;
		}

		.post-extract__content {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		.post-extract__category {
			line-height: 1;
			margin-bottom: 0;
			margin-top: 1.5rem;
		}

		.post-extract__heading {
			align-items: center;
			display: flex;
			font-size: 2rem;
			justify-content: center;
			line-height: 1;
			min-height: 2.5em;
			padding: 0.5rem 0;
			margin-bottom: 0;
		}

		.post-extract__category + .post-extract__heading {
			padding-top: 0;
		}

		.post-extract__body {
			font-size: 1rem;
		}

		.post-extract__link {
			margin-top: auto;
		}
	}
}


/**
Square version. Stacked, square image.
*/
.post-extract--square {

    .post-extract__image div {
		padding-bottom: 100%;
    }
}


/**
Short version. Stacked, short image.
*/
.post-extract--short {

    .post-extract__image div {
		padding-bottom: 75%;
    }
}


/**
Tall version. Stacked, tall image.
*/
.post-extract--tall {

    .post-extract__image div {
		padding-bottom: 65%;

		@include mq( 600px ) {
			padding-bottom: 162%;
		}
    }
}


/**
Video version. Stacked, 16:9 image.
*/
.post-extract--video {

    .post-extract__image div {
		padding-bottom: 56.25%;
    }
}



