.icon-sprite {
    display: none;
}

.icon {
    width: 1em;
    height: 1em;
    color: inherit;
    fill: currentColor;
}
