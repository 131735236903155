.questions {

    &__item {
        display: flex;
        flex-direction: column;
		margin-bottom: 2.5rem;
		position: relative;

		&.is-selected {

			&::before {
				content: ' ';
				display: block;
				background-color: brandColor(pri);
				height: 100%;
				left: -1rem;
				position: absolute;
				right: calc(100% + 1rem);
				top: 0;
				width: 5px;

				@include mq( 1024px ) {
					background-color: #888;
					left: auto;
					right: calc(100% + 1rem);
					width: 1px;
				}
			}
		}
    }

    &__heading {
		cursor: pointer;
        font-size: 2rem;
		font-weight: 300;
		margin-bottom: 1rem;

		.is-selected & {
			color: brandColor(pri);
		}
    }

    &__date {
        order: -1;
		color: inherit;
		margin-bottom: 0.75rem !important;
	}

	&__answer {
		height: 0;
		overflow: hidden;
		padding-left: 2rem;

		.is-selected & {
			height: auto;
		}
	}
}