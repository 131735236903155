ul,
ol {
	margin:0 0 2em 0;
	padding:0;
	line-height: 1.35;

	li {
		margin-bottom: 0.6em;
	}
}

ul{
	list-style-type:none;

	ul {
		margin-top:1em;
		margin-bottom:1em;

		li {
			margin-bottom:0;
		}
	}

}

ol {
	counter-reset:section;
	margin-left: 2em;

	ol {
		margin-top:-1.5em;
		margin-bottom:1em;
	}

	li {
		counter-increment:section;
		margin-bottom:.5em;
	}
}

dl {
	margin-bottom:2em;

	dt {
		font-weight:bold;

		&:not(:first-child) {
			margin-top:.5em;
		}
	}

	dd {
		margin:0;
	}
}
