.secondary-nav {
    margin-top: -4rem;
    margin-bottom: 4rem;
    text-align: center;
    background-color: palette(base, l);

    .l-wrapper {
        border-bottom: 1px solid palette(base, ui);
    }


    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }


    li {
        margin: 0 1rem;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }


    a {
        display: inline-block;
        padding: 0.7rem 0;
        font-family: $font2;
        font-size: 1rem;
        text-transform: uppercase;
        color: palette(base, font);
        text-decoration: none;
        @include tracking(210);

        &:focus,
        &:hover {
            color: palette(branding, pri);
        }
    }
}