::selection {
	background:lighten(palette(branding,pri),30);
	color:#444;
	text-shadow:none;
}

html {
	background-color:palette(base,body);
	box-sizing:border-box;
	min-height:100%;
	font-size:$base-font-size;
	font-family:$base-font-family;
	font-feature-settings:"kern", "liga", "pnum";
	line-height:$base-line-height;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	color:palette(base,font);
	min-height:100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include smoothing(false);
	font-family:$heading-font-family;
	font-size:$base-font-size * 1.2;
	line-height:$heading-line-height;
	margin:0 0 1em;
}


h1,
.alpha {
	font-size: 2.75rem;
	font-weight: bold;

	@include mq( 768px ) {
		font-size: 3.75rem;
	}
}

h2,
.beta {
	font-size: 1.5rem;
	font-weight: bold;

	@include mq( 768px ) {
		font-size: 2.25rem;
	}
}

h3,
.gamma {
	font-size: 1.5rem;
	font-weight: bold;
}

h4,
.delta {
	font-family: $font1;
	font-size: 0.875rem;
	font-weight: normal;
	@include tracking(210);
	color: palette(branding, pri);
	text-transform: uppercase;
}

.kilo {
	font-size: 6.25rem;
}

.mega {
	font-size: 18vw;

	@include mq(540px) {
	    font-size: 6.25rem;
	}

	@include mq(xl) {
	    font-size: 7vw;
	}
}

.subheading {
	font-family: $font1;
	font-size: 1.5rem;
	font-weight: normal;
	@include tracking(120);
	text-transform: uppercase;
}

p {
	margin: 0 0 1em;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color:palette(branding,pri);
	text-decoration:none;
	transition: all 0.1s ease;

	&:active,
	&:focus,
	&:hover {
		color:darken(palette(branding,pri), 25);
	}
}

hr {
	border-bottom:1px solid #e0e0e0;
	border-left:0;
	border-right:0;
	border-top:0;
	margin:0;
}


abbr,
acronym {
	border-bottom:1px dotted #ddd;
	cursor:help;
}

address {
}

hgroup {
}

del {
}

blockquote {
	margin-left: 0;
}

cite {
}

sup {
	font-size: 0.5em;
}
