.button,
#{$all-buttons} {
	appearance:none;
	background-color: transparent;
	border: 1px solid palette(branding, pri);
	border-radius:0;
	color: palette(base, font);
	cursor:pointer;
	display:inline-block;
	font-family: $font2;
	font-size: 0.875rem;
	-webkit-font-smoothing:antialiased;
	font-weight: 400;
	line-height: 3.5;
	padding: 0 2rem;
	text-align:center;
	text-decoration:none;
	transition:all 200ms ease;
	text-transform:uppercase;
	user-select:none;
	vertical-align:middle;
	white-space:nowrap;

	&:hover,
	&:focus {
		background-color:darken(palette(branding,pri),10);
		color:#fff;
	}

	&:disabled {
		cursor:not-allowed;
		opacity:0.25;

		&:hover {
			background-color:palette(branding,pri);
		}
	}

	&.button--l {
		line-height: 4.5;
		padding: 0 6rem;
	}

	&.light {
		color: #fff;
		border: 2px solid palette(branding, pri);
	}
}