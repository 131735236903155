.section {
    margin-top: 4rem;
	margin-bottom: 5rem;

		@include mq( 600px ) {
			margin-top: 7rem;
			margin-bottom: 8rem;
		}

    &--padded {
       	padding-top: 4rem;
	   	padding-bottom: 5rem;

		@include mq( 600px ) {
			padding-top: 7rem;
			padding-bottom: 8rem;
		}
    }

    &--border-bottom {
        border-bottom: 1px solid palette(base, ui);
    }

    &__heading {
        text-align: center;
        font-size: 1.875rem;
    }

    &__intro {
        max-width: 24em;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.125rem;
        text-align: center;

        @include mq(l) {
            max-width: none;
        }
    }
}