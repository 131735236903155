#vue-menu {

	@include mq( $until: 767px ) {
		max-width: 30rem;
	}
}

.menu-fields {

	@include mq( 768px ) {
		align-items: flex-start;
		display: flex;
	}

	&__left,
	&__right {

		@include mq( nav-switch ) {
			align-items: flex-start;
			display: flex;
			flex: 1 1 auto;
			flex-wrap: wrap;
			margin-left: -0.5rem;
			margin-right: -0.5rem;
		}
		@include mq( 992px ) {
			margin-left: -1rem;
			margin-right: -1rem;
		}
	}

	&__left {

		@include mq( 768px ) {
			padding-right: 3vw;
			width: 50%;
		}
		@include mq( 1100px ) {
			width: 60%;
		}
	}

	&__right {

		@include mq( 768px ) {
			padding-left: 3vw;
			width: 50%;
		}
		@include mq( 1100px ) {
			width: 40%;
		}
	}

	&__group {
		flex: 0 1 auto;
		margin-bottom: 1.5rem;
		width: 100%;

		@include mq( nav-switch ) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;

			&--quarter {
				width: 25%;
			}
			&--third {
				width: 33.3333%;
			}
			&--half {
				width: 50%;
			}
			&--two-third {
				width: 66.6666%;
			}
			&--full {
				width: 100%;
			}
		}
		@include mq( 992px ) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		&--required {

			label::after {
				color: #C00;
				content: '*';
				display: inline-block;
				margin-left: 0.25em;
			}
		}

		.btn {
			margin-top: 1rem;
			width: 100%;
		}

		.alert {
			margin-bottom: 0;
		}
	}

	&__field {

		input,
		select {
			font-size: 1rem;
			padding: 0.75em;

			@include mq( 768px ) {
				font-size: 0.875rem;
				line-height: 1 !important;
				padding: 1.5em;
			}
		}
		select {

			@include mq( 768px ) {
				height: 3.6rem;
				padding: 1.1em 1.5em;
			}
		}
		textarea {
			font-family: $font1;
			font-size: 1rem;
			line-height: 1.5;
			min-height: 10.3rem;
			padding: 0.75em;

			@include mq( 768px ) {
				font-size: 0.875rem;
				padding: 1.5em;
			}
		}

		&--select {
			position: relative;

			&::after {
				color: brandColor(pri);
				content: '\2039';
				display: block;
				font-size: 1.5em;
				pointer-events: none;
				position: absolute;
				right: 1rem;
				top: 50%;
				transform-origin: 50% 50%;
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}

	&__label {
		color: #999;
		display: block;
		font-size: 0.875rem;
		line-height: 1;
		margin-bottom: 0.5em;
	}
}