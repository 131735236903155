.site-header {
    padding: 1.5rem 0;
    border-bottom: 1px solid palette(base, ui);
	margin-bottom: 2rem;
	position: relative;

	@include mq( 600px ) {
		margin-bottom: 4rem;
	}

    .l-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    &__branding,
    &__nav-toggle {
        @include mq(nav-switch) {
            display: none;
        }
    }


    &__branding {
        width: 8rem;
        max-width: calc(100% - 4rem);
    }


    &__nav-toggle {
        z-index: index($layers, main-nav-toggle);
        position: absolute;
        left: 1rem;
	}

	.body--homepage & {

		@include mq( $until: 767px ) {
			margin-bottom: 0;
		}
	}
}