.menu-item {

	@include mq( 768px ) {
		display: flex;
	}

	&__image {
		padding-bottom: 2rem;

		@include mq( 768px ) {
			border-right: 1px solid #e0e0e0;
			flex: 1 1 auto;
			padding-bottom: 7rem;
			padding-right: 3vw;
			width: 60%;
		}

		img {
			display: block;
			height: auto;
			max-width: 100%;
			width: 40rem;
		}
	}

	&__details {
		padding-bottom: 4rem;

		@include mq( 768px ) {
			flex: 1 1 auto;
			padding-bottom: 7rem;
			padding-left: 3vw;
			width: 40%;
		}
	}

	&__heading {
		font-size: 1.5rem;
		margin-bottom: 0.25rem;
	}

	&__price {
		color: brandColor(pri);
		font-size: 0.875rem;
		margin-bottom: 1.5rem;
	}

	&__desc {
		font-size: 0.875rem;
		margin-bottom: 2rem;
	}

	&__qty {
		margin-bottom: 2rem;
		max-width: 14rem;
		position: relative;

		&::after {
			color: brandColor(pri);
			content: '\2039';
			display: block;
			font-size: 1.5em;
			pointer-events: none;
			position: absolute;
			right: 1rem;
			top: 50%;
			transform-origin: 50% 50%;
			transform: translateY(-50%) rotate(-90deg);
		}

		select {
			padding: 0.5em;
		}
	}

	&__btn {

	}

	&:last-child {

		.menu-item__details {
			padding-bottom: 0;
		}

		@include mq( 768px ) {
			.menu-item__image,
			.menu-item__details {
				padding-bottom: 0;
			}
		}
	}

	&.is-sold-out {

		.menu-item__qty {

			&::after {
				border: 1px solid #e0e0e0;
				content: '\2715  SOLD OUT';
				font-size: 0.875em;
				padding: 0.5em;
				position: static;
				transform: none;
			}

			select {
				display: none;
			}
		}
	}
}