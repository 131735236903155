.l-2-col {

	@include mq(m) {
		display: flex;
		justify-content: space-between;
	}

    /* Overide standard post-extract flex styles */
    .post-extract {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        &__image {
            width: 100%;
            margin-bottom: 1rem;
            margin-right: 0;
        }

        &__content {
            display: block;
            width: 100%;
        }
    }


    &__left {
		margin-bottom: 3rem;

        @include mq(m) {
			margin-bottom: 2rem;
            width: 65%;
            flex: 1 1 auto;
        }
		@include mq( 1024px ) {
			width: 53%;
		}

        /* Big featured post extract */
        .post-extract {
            text-align: center;

            &__image {
                margin-bottom: 3rem;
            }

            &__content {
                padding-left: 1rem;
                padding-right: 1rem;

                @include mq(l) {
                    padding-left: 3.5rem;
                    padding-right: 3.5rem;
                }
            }

            &__heading {
                margin-bottom: 2rem;
            }

            &__body {
                margin-bottom: 2rem;
                font-size: 1.125rem;
                line-height: 1.4;

                @include mq(m) {
                    font-size: 1.25rem;
                }
            }
        }
    }


    &__right {
		padding-left: 1rem;
		padding-right: 1rem;

        @include mq(m) {
            display: block;
            flex-direction: row;
            flex: 1 1 auto;
            width: 35%;
            padding-left: 3rem;
			padding-right: 0;
            min-width: 8rem;
            // max-width: 20rem;
        }
		@include mq( 1024px ) {
			width: 47%;
		}
        @include mq(xl) {
            // max-width: none;
            padding-left: 3vw;
        }

        .post-listing {
			display: flex;
			flex-wrap: wrap;
            margin: 0;
			max-width: none;
            padding-bottom: 6px;
		}
		.post-extract {
			flex: 1 1 auto;
			margin-bottom: 0;
			padding-bottom: 3rem;
			text-align: left;
			width: 100%;

			@media ( min-width: 400px ) and ( max-width: 767px ), ( min-width: 1024px ) {
				border-right: 1px solid #e1e1e1;
				padding-right: 3vw;
				width: 50%;
			}

			&:nth-child(2n) {
				border: none;
				padding-right: 0;

				@media ( min-width: 400px ) and ( max-width: 767px ), ( min-width: 1024px ) {
					padding-left: 3vw;
				}
			}

			&__image div {

				@include mq( $until: 767px ) {
					padding-bottom: 50%;
				}
			}

			&__category {
				display: none;
			}

			&__heading {
				font-size: 1.5rem;
				justify-content: flex-start;
			}

			&__body {
				font-size: 0.875rem;
			}
		}
    }
}