
.alert {
    padding: 1em;
    margin-bottom: 2em;
    border: 1px solid transparent;
    border-radius: 0.25em;
    text-align: center;

    h4 {
        font-size: 1.25em;
        font-family: $base-font-family;
        font-weight: 700;
        margin: 0;
        color: inherit;
    }
    a {
        font-weight: bold;
        text-decoration: underline;
    }
    p,
    ul {
        margin-bottom: 0;
    }
    p + p {
        margin-top: 0.5em;
    }

    &-success {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;

        hr {
            border-bottom-color: #c9e2b3;
            margin: 0.75em 0;
        }
        a,
        a:hover,
        a:focus {
            color: #2b542c;
        }
    }
    &-info {
        color: #31708f;
        background-color: #d9edf7;
        border-color: #bce8f1;

        hr {
            border-bottom-color: #a6e1ec;
        }
        a,
        a:hover,
        a:focus {
            color: #245269;
        }
    }
    &-warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;

        hr {
            border-bottom-color: #f7e1b5;
        }
        a,
        a:hover,
        a:focus {
            color: #66512c;
        }
    }
    &-danger {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;

        hr {
            border-bottom-color: #e4b9c0;
        }
        a,
        a:hover,
        a:focus {
            color: #843534;
        }
    }
}