.big-link {
    display: inline-block;
    border-bottom: 1px solid palette(branding, pri);
    font-family: $font2;
    font-size: 0.875rem;
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    @include tracking(210);
    transition: all 0.2s ease;

    &:focus,
    &:hover {
        color: palette(branding, pri);
    }
}

input.big-link {
    border-width: 0 0 1px 0;
    padding: 0;
    line-height: inherit;

    &:focus,
    &:hover {
        color: palette(branding, pri);
        background: none;
    }
}