.trees-highlight {
    position: relative;
    text-align: center;
    z-index: 1;
    overflow: hidden;

    &__content {
        position: absolute;
        bottom: 20%;
        width: 100%;

        @include mq(540px) {
            bottom: 16%;
        }
    }

    &__heading {
        color: palette(base, l);
        margin-bottom: 1rem;
        font-size: 18vw;

        @include mq(540px) {
            font-size: 6.25rem;
        }

        @include mq(xl) {
            font-size: 7vw;
        }
    }

    &__bg {
        z-index: -1;
        display: block;
        position: relative;
        width: 100%;
        min-width: 1600px;
        max-width: none;
        margin-left: 50%;
        transform: translateX(-52%);

        @include mq(l) {
            transform: translateX(-50%);
        }
    }
}


.featured-post-listing + .trees-highlight {
    z-index: 0;
    margin-top: -16rem;

    @include mq(l) {
        margin-top: -14rem;
    }
}