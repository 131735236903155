.l-wrapper {
    position: relative;;
	margin:0 auto;
    padding: 0 1rem;

    @include mq(l) {
        padding: 0 2.5rem;
        max-width: calc(#{$max-width} + 5rem);
    }

    &--flush-small {
        @include mq($until: m) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--narrow {
        @include mq(l) {
            max-width: calc(#{$max-width--narrow} + 5rem);
        }
    }

    &--xnarrow {
        @include mq(l) {
            max-width: calc(#{$max-width--xnarrow} + 5rem);
        }
    }

    &--xxnarrow {
        max-width: 56rem;
    }
}