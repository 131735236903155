$mq-show-breakpoints: (s, m, l, xl);

$mq-breakpoints: (
	xs:		320px,
	post-extract: 460px,
	s:      576px,
	m:		768px,
	l:		1024px,
	xl:		1216px,
	xxl:    1408px,
	nav-switch: 1100px,
);

// MQ debug styles
body:before {
	background:#000!important;
	border:none!important;
	bottom:0!important;
	color:#fff!important;
	display:none!important;
	opacity:.5;
	top:auto!important;
}

html[data-craftenv="local"] body:before {
	display:block!important;
}