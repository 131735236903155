.site-footer {
    padding: 3rem 0;

    .l-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 0.875rem;

        @include mq(l) {
            flex-direction: row;
        }
    }

    p {
        @include mq(l) {
            margin-bottom: 0;;
        }
    }

    .social-nav {
        order: -1;
        margin-bottom: 0;

        ul {
            margin-bottom: 1rem;
        }

        @include mq(l) {
            order: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            ul {
                margin-bottom: 0;
            }
        }
    }
}