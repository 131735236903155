.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: palette(base, ui--xl);
    backgroun-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: palette(base, l);

    @include mq(m) {
        height: 0;
		padding-bottom: 45%;

		&--no-pad {
			background-color: transparent;
			height: auto;
			padding-bottom: 0;
		}
    }

    &__content {
        padding: 4rem 1rem;

        @include mq(m) {
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__heading {
        margin-bottom: 0;
    }
}