.post-listing {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom: -5rem;

    @include mq(l) {
        max-width: none;
    }

    .post-extract {
        width: 100%;
        margin-bottom: 5rem;
    }


    &--two-col {
        @include mq(l) {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }

        .post-extract {
            @include mq(l) {
                width: 50%;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }


    &--five-col {
        @include mq(460px) {
            max-width: none;
            margin-left: -2rem;
            margin-right: -2rem;
        }

        .post-extract {

            @include mq(460px) {
                flex: 0 1 auto;
                padding-left: 2rem;
                padding-right: 2rem;
                width: 50%;
            }

            @include mq(800px) {
                width: 33.33%;

                &:nth-child(n+4) {
                    display: none;
                }
            }

            @include mq(l) {
                width: 25%;

                &:nth-child(n+4) {
                    display: block;
                }

                &:nth-child(n+5) {
                    display: none;
                }
            }

            @include mq(xl) {
                width: 20%;

                &:nth-child(n+5) {
                    display: block;
                }
			}

			&__heading {
				font-size: 1.5rem;
			}

			&__body {
				font-size: 0.875rem;
			}
        }
    }
}