.checkout {
	padding-bottom: 5rem !important;

	&__table {
		max-width: 34rem;
		margin: 0 auto 2rem;

		th {
			background-color: #000;
			color: #FFF;
			padding: 0.5rem 0.7rem;
			font-size: 14px;
			font-weight: 400;
		}

		td {
			color: palette(base, font);
		}
	}

	&__item {

		&:nth-child(2n + 1) {
			td {
				background-color: #f0f0f0;
			}
		}
	}

	&__delivery {

		td {
			border-top: 1px solid #e0e0e0;
		}
	}

	&__total {

		td {
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&__return-form {
		.big-link {
			font-size: 0.75rem;
		}
	}

	.stripe-container {
		// margin-bottom: 5rem;
		text-align: center;
	}
	.stripe-button-el {
		background-color: transparent;
		background-image: none;
		box-shadow: none;

		span {
			@extend .button;
			background-color: transparent;
			background-image: none;
			box-shadow: none;
			min-height: 0 !important;
			height: auto;
			transition: all 200ms ease;
		}

		&:hover {

			span {
				background-color: brandColor(pri);
				color: #FFF;
			}
		}
	}
}