.menu-footer {
	background-color: palette(base, ui--xl);
	padding: 1.5rem 1rem;

	@include mq( 768px ) {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 3rem;
	}

	h3 {

		@include mq( $until: 767px ) {
			padding-bottom: 1.5rem;
		}

		.total-price {
			color: brandColor(pri);
		}
		.small {
			color: #bababa;
			font-size: 0.5em;
			font-style: italic;
		}
	}

	button {

		@include mq( $until: 767px ) {
			width: 100%;
		}
	}
}