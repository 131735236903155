.featured-event {
    position: relative;
    overflow: hidden;
    background-color: palette(base, ui--xl);
    background-image: url('/img/pig-bg.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50vw 1rem 4rem;
	text-align: center;

	@include mq( 560px ) {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 35vw;
	}
	@include mq( 860px ) {
		padding-top: 18rem;
	}
    @include mq(l) {
        padding-top: 8rem;
    }

    &__heading {
        z-index: 1;
        position: absolute;
        left: -0.1em;
        top: 2rem;
        text-align: left;
        font-size: 19vw;
        color: palette(base, l);
        line-height: 0.9;
        width: 120%;
        max-width: 7em;

        @include mq(560px) {
            font-size: 15vw;
        }

        @include mq(860px) {
            font-size: 7.75rem;
            left: 2rem;
        }

        i {
            font-weight: 500;
            font-style: italic;
        }
    }

    &__content {
        position: relative;
        z-index: 2;

        @include mq(l) {
            text-align: left;
            margin-left: 52%;
        }
        @include mq(xxl) {
            margin-left: 42%;
        }
    }

    &__body {
        max-width: 28em;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.25rem;

        @include mq(l) {
			font-size: 1.125rem;
            margin: 0 0 2rem 0;
		}
		@include mq( 1300px ) {
			font-size: 1.25rem;
		}
		@include mq( 1500px ) {
			font-size: 1.375rem;
		}
    }

    &__details {

		@include mq( 768px ) {
			display: inline-flex;
			margin: 2rem auto;
		}
		@include mq( l ) {
			display: flex;
			margin: 0 0 2rem;
		}

        li {
            font-size: 1.75rem;
			font-family: $font2;
			text-align: center;

			@include mq( $until: 767px ) {

				&:first-child {
					border-top: 1px solid #000;
					margin-top: 2rem;
					padding-top: 2rem;
				}
			}
            @include mq(768px) {
				align-items: center;
				display: flex;
				flex: 0 0 auto;
                font-size: 1.125rem;
				justify-content: center;

				&:not(:last-child) {
					padding-right: 1em;
					border-right: 1px solid;
				}

				&:not(:first-child) {
					padding-left: 0.8em;
				}

				&.featured-event__location {
					flex: 0 1 auto !important;
				}
			}
			@include mq( 1300px ) {
				font-size: 1.5rem;
			}
            @include mq( 1600px ) {
                font-size: 1.875rem;
			}
        }
	}
}