body {
    padding-top: 1px;
    padding-bottom: 1px;
	background-image: url('/img/coals-128.gif');
	background-attachment: fixed;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: 100%;

    @include mq(l) {
		// background-size: auto;
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: 100% 100%;
    }
}

.sticky {
	position: sticky;
	top: 1rem;
}