form {
}

fieldset {
}

input,
label,
select {
	display:block;
	font-family: $font1;
}

label {
	color:palette(branding,pri);
	margin-bottom:.5em;

	&.required::after {
		content:"*";
	}

	abbr {
		display:none;
	}

	&.error {
		color:palette(status, error);;
		margin-top:.25em;
	}
}

#{$all-text-inputs},
select,
select[multiple=multiple] {
	appearance:none;
	border-radius:0;
	background-color:#fff;
	border:1px solid #aaa;
	color:#000;
	display:block;
	font-size: 1rem;
	padding:1.5em;
	max-width:100%;
	transition:all .3s ease;
	width:100%;

	&::placeholder {
		color: #BABABA;
	}

	&.error {
		border:1px solid palette(status, error);
	}

	&:hover {
		border-color: palette(base, font);
	}

	&:focus {
		border-color: palette(branding, pri);
		outline:none;
	}

	&:disabled {
		cursor:not-allowed;
	}

	@include mq(768px) {
		font-size: 0.875rem;
	}
}

textarea {
	resize:vertical;
}

input[type="checkbox"],
input[type="radio"] {
	display:inline;
	margin-right:.5em;

	+ label {
		display:inline-block;
	}
}

input[type="file"] {
	margin-bottom:1em;
	outline:0;
	width:100%;
}