.home-intro {
	padding-bottom: 3rem;
	text-align: center;

	&__image {
		margin-bottom: 3rem;

		img {

		}
	}

	&__content {
		padding: 0 1rem;
	}

	&__heading {
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__body {
		margin: 0 auto 2rem;
		max-width: 42rem;
	}

	&__link {

	}
}