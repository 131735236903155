/* blockquote */.pullquote {
    margin-left: 1em;
    p {
        &:first-child::before {
            margin-left: -0.38em;
        }

        &:last-child::after {
            content: "”";
        }
    }
}