/**
Use for iamges which shouldn't scale naturally.
*/
/* div */.fixed-image {
    width: 100%;
    height: 18rem;
    background-color: palette(base, ui--xl);
    background-position: 50% 50%;
    backgorund-repeat: no-repeat;
    background-size: cover;

    @include mq(m) {
        height: 0;
        padding-bottom: 28%;
    }
}
