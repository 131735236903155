.subscribe-header {
	display: none;
	overflow: hidden;
	height: 0;
	transition: height 300ms ease;

	@include mq( nav-switch ) {
		display: block;
		font-size: 0.75rem;
		max-width: 25vw;
		position: absolute;
		right: 0;
		top: 100%;
		width: 30em;
	}
	@include mq( 1300px ) {
		bottom: 0;
		font-size: 0.875rem;
		top: auto;
	}

	form {
		display: flex;
		width: 100%;

		@include mq( nav-switch ) {
			border-left: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
			border-top: 1px solid #e1e1e1;
		}
		@include mq( 1300px ) {
			border-bottom: none;
			margin-top: 1px;
		}
	}

	&__email {
		flex: 1 1 auto;
	}

	&__submit {
		flex: 0 0 auto;
		width: 3em;
	}

	input {
		border: none;
		font-size: 1em;
		padding: 1em 1.25em;
	}

	button {
		@include size(100%);
		border: none;
		color: transparent;
		display: block;
		font-size: 1em;
		line-height: 1;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-indent: -9999px;
		white-space: nowrap;

		&::after {
			@include size(1em);
			border-right: 1px solid palette(branding, pri);
			border-top: 1px solid palette(branding, pri);
			content: ' ';
			display: block;
			left: 40%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			transition: border-color 200ms ease;
		}

		&:hover {
			background-color: palette(branding, pri);

			&::after {
				border-color: #FFF;
			}
		}
	}

	&.is-active {

		@include mq( nav-switch ) {
			height: 40px;
		}
		@include mq( 1300px ) {
			height: 46px;
		}
	}
}