.l-page {
    background-color: palette(base, body);
    min-height: calc(100vh - 3rem);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include mq(m) {
        margin: 1.25rem;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main {
        flex: 1 1 auto;
    }
}