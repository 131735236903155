.responsive-video {
    position: relative;
    height: 0;
    overflow: hidden;

	/* 16x9 Aspect Ratio */
	&--16x9 {
	    padding-bottom: 56.25%;
	}

	/* 4x3 Aspect Ratio */
	&--4x3 {
	    padding-bottom: 75%;
	}

	iframe {
	    position: absolute;
	    top:0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
}