.u-sr-only {
	border:0;
	clip:rect(0,0,0,0);
	height:1px;
	margin:-1px;
	overflow:hidden;
	padding:0;
	position:absolute;
	width:1px;
}

.u-white-space-nowrap {
	white-space:nowrap !important;
}

.u-white-space-normal {
	white-space: normal !important;
}

.u-hidden {
	display:none!important;
}

.u-display-block {
	display:block!important;
}

.u-display-inline {
	display:inline!important;
}

.u-display-inline-block {
	display:inline-block!important;
}

.u-border-bottom {
	border-bottom: 1px solid palette(base, ui) !important;
}

.u-border-top {
	border-top: 1px solid palette(base, ui) !important;
}

.u-flush--top {
	margin-top: 0 !important;
}

.u-flush--bottom {
	margin-bottom: 0 !important;
}

// Pull Utilities
@for $i from -8 through -1 {
    .u-pull--top#{$i} { margin-top: #{$i}rem !important;}
}

// Push Utilities
@for $i from 0 through 10 {
    .u-push--bottom-#{$i} {
		margin-bottom: #{$i * 0.6}rem !important;

		@include mq( 600px ) {
			margin-bottom: #{$i}rem !important;
		}
	}
	.u-push--top-#{$i} {
		margin-top: #{$i * 0.6}rem !important;

		@include mq( 600px ) {
			margin-top: #{$i}rem !important;
		}
	}
}

// Pad Utilities
@for $i from 0 through 10 {
    .u-pad--bottom-#{$i} {
		padding-bottom: #{$i * 0.6}rem !important;

		@include mq( 600px ) {
			padding-bottom: #{$i}rem !important;
		}
	}
    .u-pad--top-#{$i} {
		padding-top: #{$i * 0.6}rem !important;

		@include mq( 600px ) {
			padding-top: #{$i}rem !important;
		}
	}
}

.u-img-responsive {
	display:block;
	height:auto;
	max-width:100%;
}

.u-text-pri {
	color: palette(branding, pri);
}

.u-font-normal {
	font-weight: 400;
}

.u-font-bold {
	font-weight: 600;
}

.u-text-center {
	text-align: center;
}

.u-center {
	margin: 0 auto;
}

.u-text-left {
	text-align: left;
}

.u-text-right {
	text-align: right;
}

.u-full-width {
	width: 100% !important;
}

.ghost {
    height: 0 !important;
    margin-bottom: 0 !important;
    min-height: 0 !important;
    padding: 0 !important;
    visibility: hidden !important;
}

[v-cloak] {
	visibility: hidden;
}