.split-post {
	display: flex;
	flex-wrap: wrap;

    @include mq(m) {
        justify-content: space-between;
        align-items: stretch;

        &:not(:last-child) {
            margin-bottom: 0;
        }

        & + .split-post {
            .split-post__right,
            .split-post__left {
                padding-top: 8rem;
            }
        }
    }

    &__header {
        h2,
        h3 {
            margin-bottom: 0;
        }

        margin-bottom: 2rem;
    }

	&__text-block {
		margin-left: auto;
		max-width: 63.75rem;

		&:only-child {
			margin-bottom: 0 !important;
			margin-top: 1rem !important;
		}

		> * {
			max-width: 50rem;
		}
	}

    &__left {
		// margin-bottom: 1rem;
		width: 100%;

        @include mq(m) {
			border-right: 1px solid palette(base, ui);
            margin-bottom: 0;
            padding-right: 3.5vw;
			width: 75%;

			&--text {
				border: none;
				width: 65%;

				.split-post__text-block {
					max-width: 53.85rem;
				}
			}
		}
	}

    &__right {
		order: -1;
		width: 100%;

		.meta-list {
			margin-bottom: 3rem;
			margin-top: 0;
		}

		.big-quote {
			display: none;
		}

        @include mq(m) {
			display: flex;
			flex-direction: column;
			order: 2;
            padding-left: 3.5vw;
            width: 25%;

			.meta-list {
				margin-bottom: 2rem;
				margin-top: 1em;
			}

			.big-quote {
				align-items: center;
				display: flex;
				flex: 1 1 auto;
				margin: 1.5rem 0;
			}

			&--image {
				padding-left: 0;
				width: 35%;
			}
        }
	}

	&.left-first {
		flex-wrap: nowrap;

		@include mq( $until: 767px ) {
			display: block;
		}

        .split-post__left {
			flex: 1 1 auto;
		}
		.split-post__right {
			flex: 1 0 auto;
			min-width: 290px;
		}
	}
}