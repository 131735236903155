.search-input {
	max-width: 24rem;
    position: relative;

    input {
        padding-left: 3em;
    }

    .icon {
		@include size(1.5rem);
        position: absolute;
        left: 0.75em;
        top: 50%;
        transform: translateY(-50%);
        fill: palette(branding, pri);
    }
}