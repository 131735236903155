.social-nav {
    ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    li {
        margin: 0 0.5rem;
    }

    a {
        display: block;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;

        &--facebook {
            margin-left: -0.2em;
        }
    }
}