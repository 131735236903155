.hero-video {

	&__video {
		@include size(100%);
		align-items: center;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
	}

	&__btn {
		opacity: 0.75;
		transition: opacity 200ms ease;

		img {
			@include size(8rem);
			display: block;
			transition: all 200ms ease;
		}

		&:hover {
			opacity: 1;

			img {
				@include size(9rem);
			}
		}
	}
}