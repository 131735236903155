.page {

	* {
		margin-bottom: 0;
	}

	:not(li, span, br) + :not(li, span, br) {
		margin-top: 1.5em;
	}

	.lede {
		font-size: 1.25em;
		font-style: italic;
		color: #999;
	}

	p {
		line-height: 1.6875;
	}

	a {
		border-bottom: 1px dotted;
		display: inline;
		line-height: 1;
	}

	ul {
		padding-left: 2em;

		li {
			list-style-type: disc;
		}
	}

	ol {
		padding-left: 2em;

		li {
	    	list-style-type:decimal;
	    }
	}

	li {
		line-height: 1.6875;
		margin-top: 0.35em;
	}

	blockquote {
		margin-left: 0;
		margin-right: 0;
		margin-top: unset;
		padding: 0;

		p {
			color: brandColor(ter);
			font-family: $font2;
			font-size: 1.6666em;
			line-height: 1.3333;
		}
	}

	br + br {
		display: none;
	}
}