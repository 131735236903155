.post-carousel {
	border-bottom: 1px solid palette(base, ui--xl);
	padding-bottom: 3rem;

    @include mq(m) {
        margin-left: 0;
		margin-right: 0;
	}
	@include mq( l ) {
		align-items: center;
		display: flex;
		padding-bottom: 0;
	}

    &__list {
		margin-bottom: 2rem;

		@include mq( l ) {
			flex: 1 1 auto;
			margin-bottom: 0;
			width: 60%;
		}
	}

    &__item {
		width: 100%;
    }

    &__image {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
        padding-bottom: 58%;
    }

    &__content {
		padding: 0 2rem;
		text-align: center;

		@include mq( l ) {
			flex: 1 1 auto;
			width: 40%;
		}
    }

    &__heading {
        margin-bottom: 1rem;

        @include mq(m) {
            margin-bottom: 2rem;
        }
    }

    &__body {
        margin-bottom: 2rem;
        font-size: 1.375rem;

        @include mq(m) {
            margin-bottom: 3rem;
        }
    }

    .flickity-page-dots {
        bottom: 1rem;

        // @include mq(m) {
        //     width: auto;
        //     left: 25%;
        //     transform: translateX(-50%);
        // }

        .dot {
            width: 1rem;
            height: 1rem;
            background-color: palette(base, l);
            opacity: 1;

            &.is-selected {
                background-color: palette(branding, pri);
            }
        }
    }
}