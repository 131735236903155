/* All screens */
.main-nav {
    text-align: center;
    background-color: palette(base, l);

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
    }

    a {
        @include tracking(210);
        display: inline-block;
        padding: 1em 0;
        font-family: $font2;
        font-size: 1em;
        text-transform: uppercase;
        color: palette(base, font);
        text-decoration: none;

        &:focus,
        &:hover {
            color: palette(branding, pri);
        }
	}

	li.is-active {

		a {
			color: brandColor(pri);
		}
	}
}

/* Small screen */
@include mq($until: nav-switch) {
    .main-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: center;
		transition: opacity 350ms ease;
        z-index: index($layers, main-nav);

        .main-nav-is-open & {
			opacity: 1;
			pointer-events: auto;
        }

        ul {
            height: 80vh;
            flex-direction: column;
			justify-content: space-between;
			max-height: 37.5rem;
        }

        li {
			margin-bottom: 0;
            padding: 0;
		}

		a {
			padding: 1vh 0;
		}

        &__branding {
            display: none;
        }
    }
}

/* Full Nav */
@include mq(nav-switch) {
    .main-nav {
        width: 100%;

		li {
			font-size: 0.75rem;
			margin: 0 1em;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
    }
}

/* Stacked Nav */
@include mq($from: nav-switch, $until: 1299px) {
    .main-nav {

		ul {
			flex-wrap: wrap;
		}

		&__branding {
			order: -1;
			width: 100%;
		}
    }
}

/* Big Screens */
@include mq(1500px) {
    .main-nav {

		li {
			font-size: 0.875rem;
		}
    }
}

/* Really Big Screens */
@include mq(1700px) {
    .main-nav {

		li {
			font-size: 1rem;
		}
    }
}
