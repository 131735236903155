/* dl */.meta-list {
    font-size: 0.875rem;

    dt {
        @extend .delta;
        color: inherit;
    }

    dd {
        a {
            // display: inline-block;
            border-bottom: 1px solid palette(branding, pri);
            color: inherit;
            text-decoration: none;
            transition: all 0.2s ease;

            &:focus,
            &:hover {
                color: palette(branding, pri);
            }
        }
    }

    dd + dt {
        margin-top: 1rem;
    }
}