.newsletter-signup {
    background-color: palette(base, ui--xl);
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;

    input.newsletter-signup__email {
        max-width: 35rem;
        margin: 3rem auto;
        padding: 2rem;
        border: 1px solid transparent;

        &:focus {
            border-color: palette(branding, pri);
        }
    }

    &__submit {

    }
}