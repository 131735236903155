.burger-button {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    cursor: pointer;


    .bar {
        &,
        &:before,
        &:after {
            display: block;
            position: absolute;
            top: 17px;
            left: 6px;
            height: 3px;
            width: 24px;
            background-color: palette(branding, pri);
            -webkit-backface-visibility: hidden;
            transform: rotateZ(0) scaleX(1) translateX(0) translateY(0) translateZ(0);
        }

        &:before,
        &:after {
            content: "";
            left: 0;
            transition: all 0.2s ease;
        }

        &:before {
            top: -8px;
        }

        &:after {
            top: 8px;
        }

        .main-nav-is-open & {
            background: transparent;


            &:before {
                transform: rotateZ(45deg) scaleX(1.25) translate(4px, 6px);
                background: palette(branding, pri);
            }

            &:after {
                transform: rotateZ(-45deg) scaleX(1.25) translate(4px, -6px);
                background: palette(branding, pri);
            }
        }
    }
}