.playlist {

	@include mq( $until: 767px ) {
		margin-top: 3rem;
	}

	iframe {
		height: 60vh;
		width: 100%;
	}
}