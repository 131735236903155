table {
	font-feature-settings:"kern", "liga", "tnum";
	width:100%;
}

tr,
td,
th {
	word-wrap: break-word;
	white-space: nowrap;
	vertical-align:middle;
}

th {
	background-color:rgba(#aaa,.2);
	border-right:1px solid #fff;
	color:palette(branding,pri);
	cursor:pointer;
	font-weight:700;
	padding:1em .7em;
	text-align:left;
	text-transform: uppercase;
}

td {
	color:palette(branding,sec);
	font-weight:500;
	padding:.5em .7em;
}