.featured-post-listing {
    z-index: 1;
    position: relative;
    width: 100%;

    &::-webkit-scrollbar {
        display: none
    }

    &__inner {
        margin-left: auto;
		margin-right: auto;
		max-width: 1240px;
		padding-left: 1rem;
		padding-right: 1rem;
    }

    &__list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 4rem;
        justify-content: space-around;
    }

    .section__heading {
        margin-bottom: 4rem;
    }

    .post-extract {
		flex: 1 1 auto;
		margin-bottom: 3rem;
		// padding-left: 8vw;
		// padding-right: 8vw;

        @include mq(600px) {
			flex: 0 1 auto;
			margin-bottom: 5rem;
			padding: 0 2rem;
            width: 23.125rem;
        }
	}

	&--scrolling {
		overflow-x: scroll;
		-ms-overflow-style: -ms-autohiding-scrollbar;
  		-webkit-overflow-scrolling: touch;

		.featured-post-listing__inner {
			padding: 0;

			@include mq( 600px ) {
				min-width: 1140px;
			}
		}

		.featured-post-listing__list {
			flex-wrap: nowrap;
			justify-content: space-between;
		}

		.post-extract {
			flex: 0 0 auto;
			margin-bottom: 0;

			@include mq( $until: 599px ) {
				padding-left: 8vw;
				padding-right: 8vw;
				width: 75vw;
			}
		}
	}
}